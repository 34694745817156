@media (min-width: 1001px) {
  .WosiLife {
    max-height: 100vh;
    height: 100vh;
    position: relative;
    overflow: hidden;
    &-slogan {
      width: 8rem;
      height: 8rem;
      position: relative;
      float: right;
      margin-top: 2rem;
  
      .slogan-img {
        width: 100%;
        height: 100%;
      }
  
      .slogan-word {
        font-size: .42rem;
        width: 8rem;
        text-align: center;
        position: absolute;
        bottom: -1.6rem;
        font-weight: lighter;
      }
    }
  
    &-title {
      font-size: .8rem;
      font-weight: 500;
      text-align: center;
      line-height: 8rem;
      height: 8rem;
      margin-top: 2rem;
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .WosiLife {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    &-slogan {
      position: relative;
  
      .slogan-img {
        margin-top: 2rem;
        width: 9rem;
        height: 9rem;
        margin-left: -1.5rem;
      }
  
      .slogan-word {
        font-size: .42rem;
        width: 8rem;
        bottom: -1.6rem;
        font-weight: lighter;
        padding-left: 1.5rem;
      }
    }
  
    &-title {
      font-size: .8rem;
      font-weight: 500;
      text-align: center;
      position: absolute;
      bottom: 1.4rem;
      width: 100%;
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@primary-color: #038fde;