@media (min-width: 1001px) {
  .Wosi {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  
    &-bg-1 {
      position: absolute;
      left: -8rem;
      top: -8rem;
      width: 22rem;
      height: 22rem;
      z-index: 1;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-bg-2 {
      position: absolute;
      left: 8rem;
      top: 0rem;
      width: 12rem;
      height: 12rem;
      z-index: 2;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-bg-3 {
      position: absolute;
      left: 1rem;
      top: 3rem;
      width: 14rem;
      height: 14rem;
      opacity: 1;
      z-index: 4;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-slogan {
  
      width: 100%;
      position: absolute;
      bottom: .8rem;
      z-index: 10;
  
      &-title {
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
      }
  
      &-word {
        font-size: .5rem;
        font-weight: lighter;
        text-align: center;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .Wosi {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  
    &-bg-1 {
      position: absolute;
      left: -8rem;
      top: -8rem;
      width: 22rem;
      height: 22rem;
      z-index: 1;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-bg-2 {
      position: absolute;
      left: 8rem;
      top: 0rem;
      width: 12rem;
      height: 12rem;
      z-index: 2;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-bg-3 {
      position: absolute;
      left: 1rem;
      top: 3rem;
      width: 14rem;
      height: 14rem;
      opacity: 1;
      z-index: 4;
  
      img {
        width: 100%;
        height: 100%;
      }
    }
  
    &-slogan {
  
      width: 100%;
      position: absolute;
      bottom: 1rem;
      z-index: 10;
  
      &-title {
        font-size: 2rem;
        font-weight: 500;
        text-align: center;
      }
  
      &-word {
        font-size: .6rem;
        font-weight: lighter;
        text-align: center;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}



@primary-color: #038fde;