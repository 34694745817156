@media (min-width: 1001px) {
  .WosiCloud {
    width: 19.2rem;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    &-slogan {
      height: 9rem;
      width: 15.72rem;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
  
      .slogan-img-1 {
        width: 7.58rem;
        height: 9rem;
      }
  
      .slogan-title {
        flex: 1;
        font-size: .8rem;
        font-weight: 500;
        text-align: center;
        height: 9rem;
        line-height: 9rem;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .WosiCloud {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    &-slogan {
      width: 100vw;
      height: 100vh;
      padding-top: 2rem;
  
      .slogan-img-1 {
        width: 100%;
        height: auto;
      }
  
      .slogan-title {
        position: absolute;
        bottom: 1.38rem;
        width: 100%;
        font-size: .8rem;
        font-weight: 500;
        text-align: center;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@primary-color: #038fde;