@media (min-width: 1001px) {
  .WosiAi {
    width: 19.2rem;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    &-slogan {
      height: 8.8rem;
      width: 13.6rem;
      margin: 0 auto;
      position: relative;
  
      .slogan-img-1 {
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 1;
        right: 0;
      }
  
      .slogan-img-2 {
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 2;
        lfet: 0;
      }

      .slogan-img-3 {
        display: none;
        width: 13rem;
        height: 13rem;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: -3rem;
        transform: translate(-50%, 0);
      }
  
      .slogan-title {
        position: absolute;
        width: 100%;
        font-size: .8rem;
        font-weight: 500;
        text-align: center;
        bottom: 0rem;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .WosiAi {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
    &-slogan {
      width: 100vw;
      height: 100vh;
      position: relative;
      overflow: hidden;
  
      .slogan-img-1 {
        width: 8rem;
        height: 8rem;
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 4rem;
        transform: translate(-50%, 0);
      }
  
      .slogan-img-2 {
        display: none;
      }

      .slogan-img-3 {
        width: 13rem;
        height: 13rem;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: -3.5rem;
        transform: translate(-50%, 0);
      }
  
      .slogan-title {
        position: absolute;
        width: 100%;
        font-size: .8rem;
        font-weight: 500;
        text-align: center;
        bottom: 1.4rem;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@primary-color: #038fde;