@media (min-width: 1001px) {
  .Woyou {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    
  
    &-slogan {
      width: 100%;
      margin-top: 1rem;
      z-index: 10;
      margin-bottom: 40px;
  
      // &-title {
      //   font-size: 2rem;
      //   font-weight: 500;
      //   text-align: center;
      // }

      img {
        width: 500px;
        margin: 0 auto;
        display: block;
      }
    }

    &-button {
      font-size: 20px;

      .button-mask {
        background-color: #fff;
        opacity: .4;
      }

      .button {
        width: 100%;
        // height: .53rem;
        // border: 2px solid black;
        // border-radius: 6px;
        text-align: center;
        position: relative;
        height: 70px;
        margin-bottom: 20px;

        &-img {
          width: 300px;
          cursor: pointer;
          margin: 0 auto;
        }
        // &-title {
        //   font-size: .26rem;
        // }

        // &-desc {
        //   font-size: .20rem;
        // }

        // &-mask {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 6px;
        //   position: absolute;
        //   opacity: .4;
        //   left: 0;
        //   top: 0;
        //   z-index: 100;
        //   background-color: #e3e3e3;
        // }
      }
    }

    &-link {
      position: absolute;
      bottom: .8rem;
      right: 20px;
      p {
        width: 100%;
        font-size: .3rem;
        font-weight: lighter;
        text-align: center;
        a {
        }
        a:not(:first-child) {
          margin-left: .4rem;
        }
      }

    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .Woyou {
    width: 100vw;
    height: 100vh;
    position: relative;
    overflow: hidden;
  
    &-slogan {
      width: 100%;
      margin-top: 3rem;
      z-index: 10;
  
      // &-title {
      //   font-size: 2rem;
      //   font-weight: 500;
      //   text-align: center;
      // }

      img {
        width: 70%;
        margin: 0 auto;
        display: block;
      }
    }

    .button-mask {
      background-color: #fff;
      opacity: .4;
    }

    &-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      bottom: 2rem;
      width: 100%;

      .button {
        width: 5rem;
        // height: .53rem;
        // border: 2px solid black;
        // border-radius: 6px;
        text-align: center;
        padding-bottom: .2rem;
        position: relative;

        img {
          width: 100%;
          cursor: pointer;
        }
        // &-title {
        //   font-size: .26rem;
        // }

        // &-desc {
        //   font-size: .20rem;
        // }

        // &-mask {
        //   width: 100%;
        //   height: 100%;
        //   border-radius: 6px;
        //   position: absolute;
        //   opacity: .4;
        //   left: 0;
        //   top: 0;
        //   z-index: 100;
        //   background-color: #e3e3e3;
        // }
      }
    }

    &-link {
      position: absolute;
      bottom: 1rem;
      width: 100%;
      p {
        width: 100%;
        font-size: .3rem;
        font-weight: lighter;
        text-align: center;
        a {
        }
        a:not(:first-child) {
          margin-left: .4rem;
        }
      }

    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}



@primary-color: #038fde;