
.active {
  display: block;
}
// 大屏样式
@media (min-width: 1001px) {
  .nav {
    height: .8rem;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;

    .nav-logo {
      cursor: pointer;
      height: .8rem;
      line-height: .8rem;
      font-size: .4rem;
      padding-left: 1.2rem;
    }

    .nav-menu {
      font-size: .3rem;
      flex: 1;
      position: relative;

      &-image {
        width: .4rem;
        float: right;
        padding-top: .16rem;
        padding-right: .16rem;
      }

      .nav-list {
        position: absolute;
        width: 2rem;
        border-radius: .06rem;
        top: .2rem;
        right: .7rem;
        background: linear-gradient(90deg, rgba(34, 225, 255, 1) 0%, rgba(29, 143, 225, 1) 48%, rgba(98, 94, 177, 1) 100%);
        font-size: .24rem;
        text-align: center;
        color: #F0F2F4;
        z-index: 10000000;
        overflow: hidden;
  
        .nav-list-item {
          height: .6rem;
          line-height: .6rem;
        }
  
        .nav-list-item-active {
          color: #F0F2F4;
          background-color: #0d638c;
        }
      }

      ul {
        margin: 0;
        li:hover {
          .nav-menu-icon {
            img {
              display: block;
            }
          }
        }

        .nav-menu-icon-active {
          img {
            display: block!important;
          }
        }

        li {
          display: block;
          float: right;
          height: .8rem;
          line-height: .8rem;
          margin-left: .42rem;
          cursor: pointer;

          .nav-menu-icon {
            display: inline-block;
            width: .13rem;
            height: .2rem;

            img {
              // display: none;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .nav-phone-menu {
      display: none;
    }

    .nav-phone-list {
      display: none;
    }
  }
}
// 小屏样式
@media (max-width: 1000px) {
  .nav {
    height: 1.2rem;
    padding: 0 .4rem;
    position: fixed;
    z-index: 1000;

    .nav-logo {
      cursor: pointer;
      float: left;
      height: 1.2rem;
      line-height: 1.2rem;
      font-size: .4rem;
      
    }

    .nav-menu {
      display: none;
    }

    .nav-phone-menu {
      position: fixed;
      width: 1.2rem;
      height: 1.2rem;
      right: 0;
      top: 0;

      .nav-phone-menu-image {
        padding-right: .3rem;
        padding-top: .3rem;
        display: block;
        width: .6rem;
        height: .6rem;
      }
    }

    .nav-phone-list {
      height: calc(100vh - 1.5rem);
      width: 100vw;
      position: fixed;
      border-radius: .6rem .6rem 0 0;
      top: 1.5rem;
      left: 0;
      background: linear-gradient(90deg, rgba(34, 225, 255, 1) 0%, rgba(29, 143, 225, 1) 48%, rgba(98, 94, 177, 1) 100%);
      font-size: .42rem;
      text-align: center;
      color: #F0F2F4;
      z-index: 10000000;
      overflow: hidden;

      .nav-phone-list-item {
        height: 1.28rem;
        line-height: 1.28rem;
      }

      .nav-phone-list-item-active {
        color: #F0F2F4;
        background-color: #0d638c;
      }
    }

    .fade-enter-active, .fade-leave-active {
      transition: height .5s;
    }
    .fade-enter, .fade-leave-to {
      // opacity: 0;
      height: 0;
    }

    .fade-enter-to, .fade-leave {
      // opacity: 0;
      // height: 0;
      height: calc(100vh - 1rem)!important;
    }
  }
}
@primary-color: #038fde;