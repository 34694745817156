@media (min-width: 1001px) {
  .WoyouAi {
    width: 19.2rem;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    &-slogan {
      margin: 0 auto;
      display: flex;
      flex-direction: row;
  
      .slogan-img-1 {
        width: auto;
        height: 80vh;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 1000px) {
  .WoyouAi {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    position: relative;
    &-slogan {
      width: 80vw;
      height: auto;
      // padding-top: 2rem;
      margin: 2rem auto;
  
      .slogan-img-1 {
        width: 100%;
        height: auto;
      }
    }

    .arrow {
      position: absolute;
      width: .8rem;
      height: .8rem;
      z-index: 100;
      bottom: .2rem;
      left: 50%;
      transform: translate(-50%, 0);

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
@primary-color: #038fde;