.App {
  .Content {
    .fullPage {
      .section {
        max-height: 100hv;
        height: 100vh;
        overflow: hidden;
      }
    }
  }
  .Buttom{

  }

  .App-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-size: .2rem;
    ///bottom: .05rem;
    bottom:.01rem;
    //top: .01rem;
    right: .05rem;
    left: .05rem;
    opacity: .7;
  }
}

* {
  a, a:active, a:hover {
    text-decoration: none;
  }
}
@primary-color: #038fde;